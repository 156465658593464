define(['siteObj'], (siteObj) => {
    var easiware = () => {

        const component = {};

        const _init = (element) => {
            component.element = element;
            component.elements();
            component.bindEvents();
        }

        const _elements = () => {
            component.formElements = {
                form: component.element.querySelector("#easiwareForm"),
                title: component.element.querySelector("#civilite"),
                lastName: component.element.querySelector("#nom"),
                firstName: component.element.querySelector("#prenom"),
                email: component.element.querySelector("#email"),
                telephone: component.element.querySelector("#telephone"),
                subject: component.element.querySelector("#topic"),
                order: component.element.querySelector("#commande"),
                link: component.element.querySelector("#pj"),
                message: component.element.querySelector("#message"),
                ctrlGDPR: component.element.querySelector("#ctrlGDPR"),
                submit: component.element.querySelector("#easiware_submit_button"),
                captcha: component.element.querySelector(".g-recaptcha"),
                errorContainer: component.element.querySelector(".formErrorContainer"),
                errorText: component.element.querySelector(".formError"),
                error: {
                    container: component.element.querySelector("[data-easiware-error]"),
                    title: component.element.querySelector("#easiware_error-five"),
                    lastName: component.element.querySelector("#easiware_error-three"),
                    firstName: component.element.querySelector("#easiware_error-four"),
                    email: component.element.querySelector("#easiware_error-one"),
                    message: component.element.querySelector("#easiware_error-two"),
                    ctrlGDPR: component.element.querySelector("#easiware_error-six"),
                },
                success: component.element.querySelector("[data-easiware-success]")
            }
        }

        const _bindEvents = () => {
            component.formElements.form.addEventListener("submit", component.validateForm);
            component.formElements.subject.addEventListener("change", component.setCategory);
        }

        const _validateForm = (event) => {

            event.preventDefault();

            const formData = {
                greeting: component.formElements.title.value,
                lastName: component.formElements.lastName.value,
                firstName: component.formElements.firstName.value,
                email: component.formElements.email.value,
                request: component.formElements.message.value
            };

            // Boolean identifies if form has error.
            let hasErr = false;

            if(!component.notEmpty(formData.greeting)){
                component.formElements.error.title.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.title.className = "easiware_errorMessage-text easiware_hide";
            }


            if(!component.notEmpty(formData.lastName)){
                component.formElements.error.lastName.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.lastName.className = "easiware_errorMessage-text easiware_hide";
            }

            if(!component.notEmpty(formData.firstName)){
                component.formElements.error.firstName.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.firstName.className = "easiware_errorMessage-text easiware_hide";
            }

            if(!component.isEmail(formData.email) || !component.notEmpty(formData.email)){
                component.formElements.error.email.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.email.className = "easiware_errorMessage-text easiware_hide";
            }

            if(!component.notEmpty(formData.request)){
                component.formElements.error.message.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.message.className = "easiware_errorMessage-text easiware_hide";
            }

            if(!component.formElements.ctrlGDPR.checked){
                component.formElements.error.ctrlGDPR.className = "easiware_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.ctrlGDPR.className = "easiware_errorMessage-text easiware_hide";
            }

            // Return false on form error and display error container. Otherwise, continue.
            if(hasErr) {
                event.preventDefault();
                component.formElements.error.container.className =  "easiware_error";
                return false;
            } else {
                component.formElements.error.container.className = "easiware_error easiware_hide";
                component.postMessage()
            }
        }

      const _setCategory = (subject) => {


        let requestCategory = siteObj.props.easiware.requestCategoryOne;

        if(component.formElements.subject.value === siteObj.props.easiware.subjectOne){
          requestCategory = siteObj.props.easiware.requestCategoryOne;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectTwo) {
          requestCategory =  siteObj.props.easiware.requestCategoryTwo;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectThree) {
          requestCategory =  siteObj.props.easiware.requestCategoryThree;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectFour) {
          requestCategory =  siteObj.props.easiware.requestCategoryFour;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectFive) {
          requestCategory =  siteObj.props.easiware.requestCategoryFive;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectSix) {
          requestCategory =  siteObj.props.easiware.requestCategorySix;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectSeven) {
          requestCategory =  siteObj.props.easiware.requestCategorySeven;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectEight) {
          requestCategory =  siteObj.props.easiware.requestCategoryEight;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectNine) {
          requestCategory =  siteObj.props.easiware.requestCategoryNine;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectTen) {
          requestCategory =  siteObj.props.easiware.requestCategoryTen;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectEleven) {
          requestCategory =  siteObj.props.easiware.requestCategoryEleven;
        }

        if(component.formElements.subject.value === siteObj.props.easiware.subjectTwelve) {
          requestCategory =  siteObj.props.easiware.requestCategoryTwelve;
        }

        return requestCategory;
      }

      const _postMessage = () => {

        let requestCategory = component.setCategory(component.formElements.subject.value);

        let easiwareCustomerData = {
          customer: {
            greeting: component.formElements.title.value,
            firstName: component.formElements.firstName.value,
            lastName: component.formElements.lastName.value,
            email: component.formElements.email.value,
            mobileNumber: String(component.formElements.telephone.value)
          },
          message: {
            message: component.formElements.message.value,
            orderNumber: String(component.formElements.order.value),
            category: requestCategory,
            subject: component.formElements.subject.value,
            subjectTitle: component.formElements.subject[component.formElements.subject.selectedIndex].text,
            link: component.formElements.link.value
          }
        }

            fetch('/easiware.form', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(easiwareCustomerData)
            }).then(response=> {
                if(response.ok) {
                    component.formElements.success.classList.remove("easiware_hide");
                    component.formElements.submit.classList.add("easiware_hide");
                }
                else if(response.status === 500 || response.status === 404){
                  component.formError(
                    "Unable to send Customer Service Message.");
                }
            }).catch(err=>{
                component.formError(err);
            });
        };

        const _formError = function (err) {
            component.formElements.errorContainer.classList.remove("easiware_hide");
            component.formElements.submit.classList.add("easiware_hide");
            component.formElements.errorText.innerHTML = siteObj.props.easiware.contactFormError;
        }

        const _notEmpty = function(object) {
            if (typeof (object) === 'string') {
                object = object.trim();
            }
            return (object !== '' && object !== null && object !== undefined);
        };

        const _isEmail = function(email) {
            var regex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z]{2,6})$/;
            if (component.notEmpty(email)) {
                return regex.test(email);
            } else {
                return false;
            }
        };

        component.init = _init;
        component.elements = _elements;
        component.bindEvents = _bindEvents;
        component.validateForm = _validateForm;
        component.postMessage = _postMessage;
        component.setCategory = _setCategory;
        component.formError = _formError;
        component.isEmail = _isEmail;
        component.notEmpty = _notEmpty;

        return component;
    };

    return easiware;
});
